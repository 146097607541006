<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: notificationMixin.vue
Description:This file gives the information about the notifications(ex:suceess,failure,delete etc)
-->
<script>
export default {
  methods: {
    nofm__primaryConfirmation: function (item, assign, to, input) {
      return this.$confirm(
        this.$t("Comn_primary_confirm_msg", {
          item,
          assign,
          to,
          input
        }),
        input,
        {
          confirmButtonText: this.$t("Comn_confirm"),
          cancelButtonText: this.$t("Comn_cancel"),
          cancelButtonClass: "er-button er-button--default",
          confirmButtonClass: "er-button er-button--confirm",
          type: "warning"
        }
      );
    },

    nofm__generalConfirmation: function (date, ponds, header) {
      console.log(ponds);
      return this.$confirm(
        this.$t("Comn_general_confirm_msg", {
          date,
          ponds
        }),
        header,
        {
          confirmButtonText: this.$t("Comn_confirm"),
          cancelButtonText: this.$t("Comn_cancel"),
          cancelButtonClass: "er-button er-button--default",
          confirmButtonClass: "er-button er-button--confirm",
          type: "warning"
        }
      );
    },

    nofm__deleteConfirmation: function (item, source) {
      return this.$confirm(
        this.$t("Comn_delete_confirm_msg", {
          item,
          source
        }),
        this.$t("Comn_delete"),
        {
          confirmButtonText: this.$t("Comn_confirm"),
          cancelButtonText: this.$t("Comn_cancel"),
          cancelButtonClass: "er-button er-button--default",
          confirmButtonClass: "er-button er-button--confirm",
          type: "warning"
        }
      );
    },
    nofm__notifySuccess: function ({
      msgLangkey,
      titleLangKey = "Usrs_success_msg"
    }) {
      const typeOfMsgLangKey = this.$commonUtils.getType(msgLangkey);
      if (typeOfMsgLangKey === "object") {
        const message =
          msgLangkey.count !== undefined
            ? this.$tc(msgLangkey.title, msgLangkey.count, msgLangkey.child)
            : this.$t(msgLangkey.title, msgLangkey.child);
        this.$notify({
          title: this.$t(titleLangKey),
          message,
          duration: 3000,
          type: "success"
        });
      } else {
        this.$notify({
          title: this.$t(titleLangKey),
          message: this.$t(msgLangkey),
          duration: 3000,
          type: "success"
        });
      }
    },
    nofm__notificationSuccess: function (
      msgLangkey,
      titleLangKey = "Usrs_success_msg"
    ) {
      this.$notify({
        title: this.$t(titleLangKey),
        message: this.$t(msgLangkey),
        type: "success"
      });
    },
    nofm__notificationFailed: function (msgLangkey, titleLangKey = "failed") {
      this.$notify({
        title: this.$t(titleLangKey),
        message: this.$t(msgLangkey),
        type: "error"
      });
    },
    nofm__notifyForManipulations: function (
      notifyType,
      actionPerform = "add",
      item,
      endPoint
    ) {
      const param2 = actionPerform === "add" ? "destination" : "source";
      this.$notify({
        title:
          notifyType === "success"
            ? this.$t("Usrs_success_msg")
            : this.$t("failed"),
        message: this.$t("Comn_add_success_msg", { item, [param2]: endPoint }),

        type: notifyType
      });
    },

    nofm__deleteConfirm: function (item, source) {
      this.$confirm(
        this.$t("Comn_delete_confirm_msg", {
          item,
          source
        }),
        this.$t("Comn_delete"),
        {
          confirmButtonText: this.$t("Comn_delete"),
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      )
        .then(() => {
          this.nofm__notifyForManipulations("success", "delete", item, source);
        })
        .catch(() => {
          this.nofm__notifyForManipulations("error", "delete", item, source);
        });
    },

    nofm__noDataToDownload: function (source) {
      return this.$alert(
        this.$t("Comn_no_data_to_download", { source }),
        source,
        {
          confirmButtonText: "OK",
          type: "warning"
        }
      );
    }
  }
};
</script>
<style lang="scss">
.el-message-box__message p {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
  white-space: break-spaces;
}

.el-message-box__headerbtn .el-message-box__close:hover {
  color: #f54e5e;
  @include responsiveProperty(
    font-size,
    $app_font_size_2,
    $app_font_size_3,
    $app_font_size_xl
  );
}
.el-message-box__headerbtn .el-message-box__close {
  color: #f54e5e;
  @include responsiveProperty(
    font-size,
    $app_font_size_2,
    $app_font_size_3,
    $app_font_size_xl
  );
}
.el-timeline-item__content {
  word-break: break-word;
}

.notification-mixins {
  .el-message-box__title {
    @include responsiveProperty(
      font-size,
      $app_font_size_2,
      $app_font_size_3,
      $app_font_size_xl
    );
    color: #0a2463;
    opacity: 0.9;
  }
  .el-message-box__btns button:nth-child(2) {
    margin-left: 10px;
  }

  .el-message-box__headerbtn .el-message-box__close {
    color: red;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }

  .el-message-box__content {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .el-message-box__btns {
    .el-button {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      & + & {
        margin-left: 10px;
      }
      &.el-button--default:not(.is-circle) {
        padding: 7px 15px;
      }
      &.el-button--mini:not(.is-circle) {
        padding: 5px 10px;
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
      }
      .el-button--primary {
        color: red;
      }
    }
  }
}
</style>
